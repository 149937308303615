<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Payments</span>

      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="periodTypeModel"
            placeholder="Range Type"
            :items="periodTypeItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            @change="onPeriodTypeChange"
          ></v-select>
        </v-col>

        <v-col
          v-show="showDateRange"
          cols="12"
          sm="4"
          class="pt-0 pb-0"
        >
          <v-menu
            ref="dateRangeFilterRef"
            v-model="menuDateRange"
            :return-value.sync="dateFilterModel"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            hide-details
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Dates Range"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateFilterModel"
              range
              no-title
              scrollable
              hide-details
            >
              <v-btn
                block
                outlined
                small
                dense
                @click="onDateRangeFilterClick"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="12"
          sm="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-totals">
              $ {{ formatCurrency(data.total) }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import {
  mdiClockFast, mdiTrendingUp, mdiDotsVertical, mdiPercentOutline,
} from '@mdi/js'
import { formatCurrency } from '@core/utils/filter'
import periodType from '@/constants/period-type'
import store from '@/store'

export default {
  setup() {
    const showDateRange = ref(false)
    const menuDateRange = ref(false)
    const dateFilterModel = ref([
      new Date().toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ])

    const dateRangeFilterRef = ref(null)

    const periodTypeItems = ref([])
    const periodTypeModel = ref(null)

    const loading = ref(false)
    const merchantPaymentsStatusModel = ref({})

    const statisticsData = ref([])

    const resolveStatisticsIconVariation = data => {
      if (data === 'Total Paid') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Total Pending') return { icon: mdiClockFast, color: 'success' }
      if (data === 'Total Fees') return { icon: mdiPercentOutline, color: 'warning' }
      if (data === 'Net Balance') return { icon: mdiTrendingUp, color: 'info' }

      return { icon: mdiTrendingUp, color: 'success' }
    }

    const fetchConfig = () => {
      loading.value = true
      store
        .dispatch('finance-dashboards/fetchConfig')
        .then(response => {
          const config = response.data
          periodTypeItems.value = config.periodTypes
          periodTypeModel.value = periodTypeItems.value.find(x => x.value === periodType.DAY).value
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const fetchMerchantPaymentsStatus = () => {
      loading.value = true
      store
        .dispatch('finance-dashboards/fetchMerchantPaymentsStatus',
          {
            periodType: periodTypeModel.value,
            fromDate: dateFilterModel.value[0],
            toDate: dateFilterModel.value[1],
          })
        .then(response => {
          statisticsData.value = [{
            title: 'Total Paid',
            total: response.data.totalPaid,
          },
          {
            title: 'Total Pending',
            total: response.data.totalPending,
          },
          {
            title: 'Total Fees',
            total: response.data.totalFees,
          },
          {
            title: 'Net Balance',
            total: response.data.totalPaid - response.data.totalFees,
          }]
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const onPeriodTypeChange = code => {
      showDateRange.value = (code === periodType.CUSTOM)
      periodTypeModel.value = code
      fetchMerchantPaymentsStatus()
    }

    const onDateRangeFilterClick = () => {
      dateRangeFilterRef.value.save(dateFilterModel.value)
      fetchMerchantPaymentsStatus()
    }
    const dateRangeText = computed(() => dateFilterModel.value.join(' ~ '))

    onMounted(() => {
      fetchConfig()
      fetchMerchantPaymentsStatus()
    })

    return {
      statisticsData,
      resolveStatisticsIconVariation,
      onPeriodTypeChange,
      fetchMerchantPaymentsStatus,
      fetchConfig,
      periodTypeItems,
      periodTypeModel,
      showDateRange,
      dateFilterModel,
      menuDateRange,
      loading,
      dateRangeText,
      merchantPaymentsStatusModel,
      formatCurrency,
      onDateRangeFilterClick,
      dateRangeFilterRef,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style scoped>
  .font-totals {
    font-weight: 500!important;
  }
</style>