<template>
  <v-card>
    <v-card-title class="align-start">
      <div>
        <p class="mb-2">
          Monthly Payments
        </p>
        <small class="text--secondary text-base">Total $ {{ formatCurrency(totalMonthlyOrdersComputed) }}</small>
      </div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        ref="chartRef"
        height="250"
        :options="chartOptions"
        :series="chartSeries"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import {
  onMounted, ref, computed,
} from '@vue/composition-api'
import { getVuetify, addAlpha } from '@core/utils'
import { kFormatter, formatCurrency } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const chartSeries = ref([])
    const totalMonthlyOrders = ref(0)

    const chartRef = ref(null)

    const $vuetify = getVuetify()

    const chartOptions = ref({
      colors: [
        $vuetify.theme.currentTheme.primary,
        addAlpha($vuetify.theme.currentTheme.primary, 0.9),
        addAlpha($vuetify.theme.currentTheme.primary, 0.7),
        addAlpha($vuetify.theme.currentTheme.primary, 0.5),
        addAlpha($vuetify.theme.currentTheme.primary, 0.3),
        addAlpha($vuetify.theme.currentTheme.secondary, 0.1),
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          formatter: value => `$${formatCurrency(value)}`,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
    })

    const fetchMerchantBalance = () => {
      store
        .dispatch('finance-dashboards/fetchMerchantPaymentsChart', {
          periodType: 'month',
        })
        .then(response => {
          totalMonthlyOrders.value = response.data.total
          chartOptions.value.xaxis.categories = response.data.categories
          chartSeries.value = [{ data: response.data.data }]
          chartRef.value.updateOptions(chartOptions.value)

          // chartRef.value.updateSeries(chartSeries.value)
        })
        .catch(error => error)
        .finally(() => {
        })
    }

    onMounted(() => {
      fetchMerchantBalance()
    })

    const totalMonthlyOrdersComputed = computed(() => totalMonthlyOrders.value)

    return {
      chartOptions,
      chartSeries,
      fetchMerchantBalance,
      totalMonthlyOrdersComputed,
      formatCurrency,
      totalMonthlyOrders,
      kFormatter,
      chartRef,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
